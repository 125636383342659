// src/blog/Photos.js
import React from 'react';
import '../styles/App.css';
import Gallery from './Gallery';

const Photos = () => (
    <main className="main">
        <h4>Some photos I've taken: </h4>
        <Gallery />
       
    </main>
);

export default Photos;