// src/blog/imageData.js

import image1 from './assets/img1.jpg';
import image2 from './assets/img2.jpg';
import image3 from './assets/img3.jpg';
import image4 from './assets/img4.jpg';


const imageData = [
  {
    id: 1,
    category: 'art',
    imageUrl: image1,
    description: 'The Boxer at Rest',
    location: 'Italy',
    photographer: 'Aly Brewer'
  },
  {
    id: 2,
    category: 'art',
    imageUrl: image2,
    description: 'Diskobolos',
    location: 'Italy',
    photographer: 'Aly Brewer'
  },
  {
    id: 3,
    category: 'art',
    imageUrl: image3,
    description: 'Statue of Augustus',
    location: 'Italy',
    photographer: 'Aly Brewer'
  },
  {
    id: 4,
    category: 'urban',
    imageUrl: image4,
    description: 'Sorrento, Italy',
    location: 'Italy',
    photographer: 'Aly Brewer'
  },
];

export default imageData;