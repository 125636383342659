// src/blog/Other.js
import React from 'react';
import '../styles/App.css';

const Other = () => (
    <main className="main">
        <p>This is a placeholder for the "other" page</p>
    </main>
);

export default Other;