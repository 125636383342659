// src/components/Footer.js
import React from 'react';
import '../styles/App.css';

const Footer = () => (
    <footer className="footer">
                <p>Alyssa Brewer © 2024</p>
    </footer>

);

export default Footer;